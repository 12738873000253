import React, { Component } from 'react'

import ResetPasswordForm from './ResetPasswordForm'

import logo from '../../images/logo.png'
import './App.scss'

class App extends Component {
	static getFormProps () {
		const hashData = window.location.hash.substr(1).split('&&', 2)

		return {
			email: hashData[0],
			token: hashData[1]
		}
	}

	render () {
		const hashProps = App.getFormProps()
		let content = <ResetPasswordForm {...hashProps} />

		if (!hashProps.email.length || !hashProps.token.length)
		{
			content = <span>Not enough parameters!</span>
		}

		return (
			<div className="app">
				<header className="app-header">
					<img src={logo} className="app-header__logo" alt="logo" />
				</header>

				<div className="app-content">
					{content}
				</div>
			</div>
		);
	}
}

export default App
