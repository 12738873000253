import React, { Component } from 'react'
import axios from 'axios'
import { Field, Form } from 'react-final-form'

class ResetPasswordForm extends Component
{
	constructor (props) {
		super(props)

		this.state = {
			passwordChanged: false
		}
	}

	onSubmit = async (values) => {
		axios.post('/api/password/reset', {
			email: this.props.email,
			token: this.props.token,
			password: values.password,
			password_confirmation: values.passwordRepeat
		}).then(() => {
			this.setState({ passwordChanged: true });
		}).catch((error) => {
			window.alert(error.response.data.message)
		})
	}

	onValidate = (values) => {
		const errors = {}

		if (!values.password)
		{
			errors.password = 'Password field is required'
		}
		else if (values.password.length < 6)
		{
			errors.password = 'Password must be at least 6 characters long'
		}
		else if (values.password !== values.passwordRepeat)
		{
			errors.passwordRepeat = 'Passwords must match'
		}

		return errors
	}

	render () {
		const successChange = (
			<div className="restore-password-result">
				<span className="restore-password-result__title">Password has been changed.</span>
			</div>
		)

		const form = (
			<Form
				onSubmit={this.onSubmit}
				validate={this.onValidate}
				render={({handleSubmit, pristine, invalid}) => (
					<form className="restore-password" onSubmit={handleSubmit}>
						<span className="restore-password__title">
							Please enter new password for <strong>{this.props.email}</strong>
						</span>

						<Field name="password">
							{({ input, meta }) => (
								<div>
									<input className="restore-password__input" {...input} type="password" placeholder="New password" />
									{meta.error && meta.touched && <span className="restore-password__error">{meta.error}</span>}
								</div>
							)}
						</Field>

						<Field name="passwordRepeat">
							{({ input, meta }) => (
								<div>
									<input className="restore-password__input" {...input} type="password" placeholder="Repeat password" />
									{meta.error && meta.touched && <span className="restore-password__error">{meta.error}</span>}
								</div>
							)}
						</Field>

						<button className="restore-password__button"
								type="submit"
								disabled={pristine || invalid}>Reset password</button>
					</form>
				)}
			/>
		)

		return this.state.passwordChanged ? successChange : form
	}
}

export default ResetPasswordForm